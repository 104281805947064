import {InjectionToken} from '@angular/core'
import {XLSX$Utils} from 'xlsx'

/**
 * Defines and exports an injection token that replaces the browser
 * window object.
 */
export const WINDOW = new InjectionToken<Window>('window')

/**
 * Defines and exports an injection token that replaces the browser
 * window object.
 */
export const XLSX_PROVIDER = new InjectionToken<XLSX$Utils>('xlsx')