/**
 * This service is intended to be used to share some application
 * state...
 */
import {Injectable} from '@angular/core'
import {BehaviorSubject} from 'rxjs'

 
export enum ApplicationViews {
  OFFICE, // Kontor, dvs bankkontor
  CREDIT, // Kreditavdelning
  INTERNAL_SUPPORT, // Internstöd
  CUSTOMER_SUPPORT // Kundstöd
}

export interface ApplicationState {

  /**
   * A string location outside the main view. E.g. bulk or stats
   */
  location: string

  /**
   * Set the main view filter
   */
  view: ApplicationViews

  /**
   * Tells if we want list or grid view in defectTypes
   */
  defectListView: boolean

}

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  /**
   * The initial state of the application
   */
  public state: ApplicationState = {
    location: 'base',
    view: ApplicationViews.INTERNAL_SUPPORT,
    defectListView: true,
  }

  /**
   * The application state as a behavior subject.
   */
  public applicationState = new BehaviorSubject<ApplicationState>(undefined)

  constructor() {
    // Subscribe to our own state so that we can update
    this.applicationState.subscribe({
      next: (state: ApplicationState) => {
        this.state = state
      }
    })
  }

  public selectView(view: ApplicationViews): void {
    this.state.view = view
    this.applicationState.next(this.state)
  }

  public setListView(list: boolean): void {
    this.state.defectListView = list
    this.applicationState.next(this.state)
  }
}
